<template>
  <div class="dashboard">
    <Header />
    <div class="container" style="margin-top:50px;">
      <h4 class="left-align">Sommaires</h4>
      <div class="col m12 z-depth-3">
        <div class="row table">
          <div class="col m12">
            <Summary />
          </div>
        </div>
      </div>
      <div class="col s12">
        <h5 class="left-align">Factures</h5>
        <div class="card">
          <ReceiptsTable />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import ReceiptsTable from "../components/dashboard/ReceiptsTable";
import Summary from "../components/dashboard/Summary";

export default {
  name: "Dashboard",
  data() {
    return {
      info: "",
    };
  },
  components: {
    Header,
    Footer,
    ReceiptsTable,
    Summary
  }
};
</script>

<style scoped>
.table {
  padding-top: 10px;
}
</style>
